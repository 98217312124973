import React from 'react'
import Table from '../Table'
import { TARGET_TYPE_IG12_API, PATHOGEN_LABELS_API } from '../../utils/const'
import { useTranslation } from 'react-i18next'
import { group } from 'd3-array'

const IG12Table = ({ regions, region }) => {
  const { t } = useTranslation()
  // const NUM_COUNTRIES = NUM_COUNTRIES_IN_REG[region] //number of countries
  // let years
  // const wrangle12TableData = (data) => {
  //   const allYears = []
  //   const allPathogens = []
  //   const allData = Object.entries(data)
  //     .map(([key, value]) => {
  //       const keysToExclude = [
  //         'memberState',
  //         'iSO3',
  //         'id',
  //         'region',
  //         'regionType',
  //         'incomeGroup',
  //         'uNICEFRegion'
  //       ]
  //       if (!keysToExclude.includes(key) && value !== null) {
  //         if (key.startsWith('mNT')) key = key.slice(2) //work around for the 'N' in 'mNT' because otherwise can't split on capital 'N', argh...
  //         let pathogen, year, column, keyArray
  //         if (key.includes('NotEndorsed')) {
  //           keyArray = key.split('N')
  //           column = 'NotEndorsed'
  //         } else if (key.includes('ReEstablished')) {
  //           keyArray = key.split('R')
  //           column = 'ReEstablished'
  //         } else if (key.includes('NewlyAchieved')) {
  //           keyArray = key.split('N')
  //           column = 'NewlyAchieved'
  //         } else if (key.includes('NotAchieved')) {
  //           keyArray = key.split('N')
  //           column = 'NotAchieved'
  //         } else if (key.includes('Achieved')) {
  //           keyArray = key.split('A')
  //           column = 'Achieved'
  //         }

  //         year = keyArray[0].slice(-4)
  //         pathogen = keyArray[0].slice(0, -4)

  //         allYears.push(year)
  //         allPathogens.push(pathogen === 'T' ? 'mNT' : pathogen)
  //         return {
  //           pathogen: pathogen === 'T' ? 'mNT' : pathogen,
  //           year,
  //           column,
  //           value
  //         }
  //       } else return null
  //     })
  //     .filter((item) => item)

  //   years = Array.from(new Set(allYears))
  //   const pathogens = Array.from(new Set(allPathogens))

  //   const consolidatedData = pathogens
  //     .map((pathogen) => {
  //       return years.map((year) => {
  //         const pathogenData = allData
  //           .filter((d) => d.pathogen === pathogen)
  //           .filter((d) => d.year === year)
  //           .reduce((acc, d) => {
  //             acc[d.column] = d.value

  //             return acc
  //           }, {})
  //         return Object.assign(
  //           {
  //             year,
  //             pathogen
  //           },
  //           pathogenData
  //         )
  //       })
  //     })
  //     .map((item) => {
  //       return item.reduce((acc, d) => {
  //         acc['type'] = t(TARGET_TYPE_IG12[d.pathogen]) // type will be blank for any pathogen that is not the first in its group -- see the const
  //         acc['pathogen'] = t(PATHOGEN_LABELS[d.pathogen])
  //         acc[d.year] =
  //           d.Achieved === undefined
  //             ? t('Not measured')
  //             : `${d.Achieved + d.NewlyAchieved} ${t('out of')} ${
  //                 NUM_COUNTRIES - d.NotEndorsed
  //               }`
  //         return acc
  //       }, {})
  //     })

  //   return consolidatedData
  // }
  const BASELINE_YEAR = 2020

  let years
  const wrangleIg12TableData = (data) => {
    data.forEach((d) => {
      const pathogen = d.INDCODE.split('_')[2]
      d.pathogen = pathogen
    })
    const dataMap = group(data, (d) => d.pathogen)

    const groupedByPathogen = Array.from(dataMap.values())

    return groupedByPathogen.map((p) => {
      const yearMap = group(p, (d) => d.YEAR)

      years = Array.from(yearMap.keys())
        .sort((a, b) => (a > b ? 1 : -1))
        .slice(1) //to get rid of the baseline year
      const groupedByYear = Array.from(yearMap.values())

      const yearData = groupedByYear.map((y) => {
        return y.reduce((acc, d) => {
          acc['year'] = d.YEAR
          acc['pathogen'] = d.pathogen
          acc['denominator'] = d.DENOMINATOR
          if (d.DIMENSION2 === 'Achieved') {
            acc['Achieved'] = d.NUMERATOR
          } else if (d.DIMENSION2 === 'Not Achieved') {
            acc['NotAchieved'] = d.NUMERATOR
          } else if (d.DIMENSION2 === 'Re-established') {
            acc['ReEstablished'] = d.NUMERATOR
          }
          return acc
        }, {})
      })
      return yearData
        .sort((a, b) => (a.year > b.year ? 1 : -1))
        .reduce((acc, d) => {
          acc['type'] = t(TARGET_TYPE_IG12_API[d.pathogen]) // type will be blank for any pathogen that is not the first in its group -- see the const
          acc['pathogen'] = t(PATHOGEN_LABELS_API[d.pathogen])
          acc[d.year === BASELINE_YEAR ? 'Baseline' : d.year] = `${
            d.Achieved === undefined ? 0 : d.Achieved
          } ${t('out of')} ${d.denominator}`
          return acc
        }, {})
    })
  }

  const orderedPathogens = [
    t(PATHOGEN_LABELS_API['POLIO']),
    t(PATHOGEN_LABELS_API['MEASLES']),
    t(PATHOGEN_LABELS_API['RUBELLA']),
    t(PATHOGEN_LABELS_API['NTETANUS'])
    // t(PATHOGEN_LABELS['hepb']),
    // t(PATHOGEN_LABELS['hpv']),
    // t(PATHOGEN_LABELS['tetanus']),
    // t(PATHOGEN_LABELS['jev']),
  ]
  // const orderedPathogens = [
  //   t(PATHOGEN_LABELS['wPV']),
  //   t(PATHOGEN_LABELS['measles']),
  //   t(PATHOGEN_LABELS['rubella']),
  //   t(PATHOGEN_LABELS['hepb']),
  //   t(PATHOGEN_LABELS['hpv']),
  //   t(PATHOGEN_LABELS['tetanus']),
  //   t(PATHOGEN_LABELS['jev']),
  //   t(PATHOGEN_LABELS['mNT'])
  // ]

  //needs to be sorted so that horizontal lines can show up at correct places
  const sortedData = (regions) => {
    const data = wrangleIg12TableData(regions)
    return orderedPathogens
      .map((pathogen) => data.find((d) => d.pathogen === pathogen))
      .filter((item) => item)
  }

  const ig12Data = sortedData(regions)
  const data = React.useMemo(() => ig12Data, [ig12Data])

  const yearHeaders = years?.map((year) => {
    return {
      Header: `${year}`,
      accessor: `${year}`
    }
  })

  const columns = React.useMemo(() => {
    const baseHeader = [
      {
        Header: t('target type'),
        accessor: 'type' // accessor is the "key" in the data
      },
      {
        Header: t('disease'),
        accessor: 'pathogen' // accessor is the "key" in the data
      },
      {
        Header: `${t('Baseline')} (${BASELINE_YEAR})`,
        accessor: 'Baseline' // accessor is the "key" in the data
      }
    ]
    return baseHeader.concat(yearHeaders)
  }, [yearHeaders, t])

  //Table is customized for ig1.2: if the value of the target type cell is not blank, that row will have a border at the top
  return (
    <Table
      data={data}
      columns={columns}
      noBoldTopRow={true}
      ig={'12'}
      numYearCols={years.length + 1}
      description={t(
        'A table that lists diseases categorized by target type and the number of countries achieving the target each year.'
      )}
    />
  )
}

export default IG12Table
