import * as React from 'react'
import { graphql } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { PageContent, ChartTitle } from '../styles/SharedStyles'
//import Header from '../components/Header'
import Notes from '../components/Notes'
import PageHeader from '../components/PageHeader'
import IG12Chart from '../components/IG12/IG12Chart'
import IG12Table from '../components/IG12/IG12Table'
import Footer from '../components/Footer'
import ImageExport from '../components/ImageExport'
import {
  UNI_REGIONS,
  INCOME_GROUPS,
  LOCATION_LABELS,
  WHO_LOCATION_OPTIONS,
  INC_LOCATION_OPTIONS,
  UNI_LOCATION_OPTIONS,
  REGION_LOOKUP
} from '../utils/const'
//import InsufficientData from '../components/InsufficientData'
import {
  useQueryParamString,
  getQueryParams
} from 'react-use-query-param-string'
import SelectRegion from '../components/SelectRegion'
import RegionInfo from '../components/RegionInfo'
import Disclaimer from '../components/Disclaimer'

const Ig12Page = ({ data, pageContext }) => {
  const { t } = useTranslation()

  const { sectionId } = pageContext
  const { content, footerContent } = data

  const params = getQueryParams()
  const [reg, setReg] = useQueryParamString('reg', params.reg ?? 'GBL')

  const isGbl = reg === 'GBL' || !reg
  const isUni = UNI_REGIONS.includes(reg)
  const isInc = INCOME_GROUPS.includes(reg)

  const regionLabel = isGbl ? t('global') : t(LOCATION_LABELS[reg])
  //console.log('data', data)
  return (
    <>
      <PageHeader
        sectionId={sectionId}
        title={content.title}
        eyebrowText={content.eyebrowText}
      />
      {/* <Header content={content} /> */}
      <PageContent>
        <SelectRegion
          reg={reg}
          setReg={setReg}
          whoOptions={WHO_LOCATION_OPTIONS}
          incOptions={INC_LOCATION_OPTIONS}
          uniOptions={UNI_LOCATION_OPTIONS}
        />

        <ChartTitle>{`${content.chartTitle}—${regionLabel}`}</ChartTitle>

        <>
          <ImageExport
            title={`${content.chartTitle}—${regionLabel}`}
            dataSource={content.dataSourceNotesRt}
          >
            <IG12Chart
              data={data.regions.nodes.filter(
                (d) => d.CODE === REGION_LOOKUP[reg]
              )}
              // data={
              //   isGbl
              //     ? data.global
              //     : isInc
              //     ? data.income.nodes.find((d) => d.incomeGroup === reg)
              //     : isUni
              //     ? data.unicef.nodes.find((d) => d.uNICEFRegion === reg)
              //     : data.regions.nodes.find((d) => d.iSO3 === reg)
              // }
              region={reg}
              //notes={content.chartNotesRt}
              print={true}
            />
          </ImageExport>

          <IG12Chart
            data={data.regions.nodes.filter(
              (d) => d.CODE === REGION_LOOKUP[reg]
            )}
            // data={
            //   isGbl
            //     ? data.global
            //     : isInc
            //     ? data.income.nodes.find((d) => d.incomeGroup === reg)
            //     : isUni
            //     ? data.unicef.nodes.find((d) => d.uNICEFRegion === reg)
            //     : data.regions.nodes.find((d) => d.iSO3 === reg)
            // }
            region={reg}
            notes={content.chartNotesRt}
          />
          <IG12Table
            regions={data.regions.nodes.filter(
              (d) => d.CODE === REGION_LOOKUP[reg]
            )}
            // regions={
            //   isGbl
            //     ? data.global
            //     : isInc
            //     ? data.income.nodes.find((d) => d.incomeGroup === reg)
            //     : isUni
            //     ? data.unicef.nodes.find((d) => d.uNICEFRegion === reg)
            //     : data.regions.nodes.find((d) => d.iSO3 === reg)
            // }
            region={reg}
          />
        </>
        {!isGbl && (isUni || isInc) && <Disclaimer />}
        {!isGbl && <RegionInfo reg={reg} />}
      </PageContent>
      <Notes content={content} />
      <Footer content={footerContent} />
    </>
  )
}

export default Ig12Page

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    content: googleIg12CmsSheet(language: { eq: $language }) {
      id
      language
      eyebrowText
      title
      intro1Heading
      intro1Text
      intro2Heading
      intro2Text
      chartTitle
      chartNotesRt
      definitionNotesRt
      measurementNotesRt
      calculationNotesRt
      dataSourceNotesRt
    }
    footerContent: googleFooterCmsSheet(language: { eq: $language }) {
      citationRt
      descriptionRt
    }
    regions: allRegions2(filter: { IA2030_IND: { eq: "IG1.2" } }) {
      nodes {
        IA2030_IND
        CATEGORY
        CODE
        YEAR
        INDCODE
        DIMENSION2
        VALUE_NUM
        NUMERATOR
        DENOMINATOR
      }
    }
  }
`
//     global: googleIg12Sheet(regionType: { eq: "GBL" }) {
//       id
//       memberState
//       iSO3
//       regionType
//       region
//       measles2020NotEndorsed
//       measles2020ReEstablished
//       measles2020NewlyAchieved
//       measles2020Achieved
//       measles2020NotAchieved
//       measles2021NotEndorsed
//       measles2021ReEstablished
//       measles2021NewlyAchieved
//       measles2021Achieved
//       measles2021NotAchieved
//       measles2022NotEndorsed
//       measles2022ReEstablished
//       measles2022NewlyAchieved
//       measles2022Achieved
//       measles2022NotAchieved
//       measles2023NotEndorsed
//       measles2023ReEstablished
//       measles2023NewlyAchieved
//       measles2023Achieved
//       measles2023NotAchieved
//       measles2024NotEndorsed
//       measles2024ReEstablished
//       measles2024NewlyAchieved
//       measles2024Achieved
//       measles2024NotAchieved
//       measles2025NotEndorsed
//       measles2025ReEstablished
//       measles2025NewlyAchieved
//       measles2025Achieved
//       measles2025NotAchieved
//       measles2026NotEndorsed
//       measles2026ReEstablished
//       measles2026NewlyAchieved
//       measles2026Achieved
//       measles2026NotAchieved
//       measles2027NotEndorsed
//       measles2027ReEstablished
//       measles2027NewlyAchieved
//       measles2027Achieved
//       measles2027NotAchieved
//       measles2028NotEndorsed
//       measles2028ReEstablished
//       measles2028NewlyAchieved
//       measles2028Achieved
//       measles2028NotAchieved
//       measles2029NotEndorsed
//       measles2029ReEstablished
//       measles2029NewlyAchieved
//       measles2029Achieved
//       measles2029NotAchieved
//       measles2030NotEndorsed
//       measles2030ReEstablished
//       measles2030NewlyAchieved
//       measles2030Achieved
//       measles2030NotAchieved

//       rubella2020NotEndorsed
//       rubella2020ReEstablished
//       rubella2020NewlyAchieved
//       rubella2020Achieved
//       rubella2020NotAchieved
//       rubella2021NotEndorsed
//       rubella2021ReEstablished
//       rubella2021NewlyAchieved
//       rubella2021Achieved
//       rubella2021NotAchieved
//       rubella2022NotEndorsed
//       rubella2022ReEstablished
//       rubella2022NewlyAchieved
//       rubella2022Achieved
//       rubella2022NotAchieved
//       rubella2023NotEndorsed
//       rubella2023ReEstablished
//       rubella2023NewlyAchieved
//       rubella2023Achieved
//       rubella2023NotAchieved
//       rubella2024NotEndorsed
//       rubella2024ReEstablished
//       rubella2024NewlyAchieved
//       rubella2024Achieved
//       rubella2024NotAchieved
//       rubella2025NotEndorsed
//       rubella2025ReEstablished
//       rubella2025NewlyAchieved
//       rubella2025Achieved
//       rubella2025NotAchieved
//       rubella2026NotEndorsed
//       rubella2026ReEstablished
//       rubella2026NewlyAchieved
//       rubella2026Achieved
//       rubella2026NotAchieved
//       rubella2027NotEndorsed
//       rubella2027ReEstablished
//       rubella2027NewlyAchieved
//       rubella2027Achieved
//       rubella2027NotAchieved
//       rubella2028NotEndorsed
//       rubella2028ReEstablished
//       rubella2028NewlyAchieved
//       rubella2028Achieved
//       rubella2028NotAchieved
//       rubella2029NotEndorsed
//       rubella2029ReEstablished
//       rubella2029NewlyAchieved
//       rubella2029Achieved
//       rubella2029NotAchieved
//       rubella2030NotEndorsed
//       rubella2030ReEstablished
//       rubella2030NewlyAchieved
//       rubella2030Achieved
//       rubella2030NotAchieved

//       wPV2020NotEndorsed
//       wPV2020ReEstablished
//       wPV2020NewlyAchieved
//       wPV2020Achieved
//       wPV2020NotAchieved
//       wPV2021NotEndorsed
//       wPV2021ReEstablished
//       wPV2021NewlyAchieved
//       wPV2021Achieved
//       wPV2021NotAchieved
//       wPV2022NotEndorsed
//       wPV2022ReEstablished
//       wPV2022NewlyAchieved
//       wPV2022Achieved
//       wPV2022NotAchieved
//       wPV2023NotEndorsed
//       wPV2023ReEstablished
//       wPV2023NewlyAchieved
//       wPV2023Achieved
//       wPV2023NotAchieved
//       wPV2024NotEndorsed
//       wPV2024ReEstablished
//       wPV2024NewlyAchieved
//       wPV2024Achieved
//       wPV2024NotAchieved
//       wPV2025NotEndorsed
//       wPV2025ReEstablished
//       wPV2025NewlyAchieved
//       wPV2025Achieved
//       wPV2025NotAchieved
//       wPV2026NotEndorsed
//       wPV2026ReEstablished
//       wPV2026NewlyAchieved
//       wPV2026Achieved
//       wPV2026NotAchieved
//       wPV2027NotEndorsed
//       wPV2027ReEstablished
//       wPV2027NewlyAchieved
//       wPV2027Achieved
//       wPV2027NotAchieved
//       wPV2028NotEndorsed
//       wPV2028ReEstablished
//       wPV2028NewlyAchieved
//       wPV2028Achieved
//       wPV2028NotAchieved
//       wPV2029NotEndorsed
//       wPV2029ReEstablished
//       wPV2029NewlyAchieved
//       wPV2029Achieved
//       wPV2029NotAchieved
//       wPV2030NotEndorsed
//       wPV2030ReEstablished
//       wPV2030NewlyAchieved
//       wPV2030Achieved
//       wPV2030NotAchieved

//       jev2020NotEndorsed
//       jev2020ReEstablished
//       jev2020NewlyAchieved
//       jev2020Achieved
//       jev2020NotAchieved
//       jev2021NotEndorsed
//       jev2021ReEstablished
//       jev2021NewlyAchieved
//       jev2021Achieved
//       jev2021NotAchieved
//       jev2022NotEndorsed
//       jev2022ReEstablished
//       jev2022NewlyAchieved
//       jev2022Achieved
//       jev2022NotAchieved
//       jev2023NotEndorsed
//       jev2023ReEstablished
//       jev2023NewlyAchieved
//       jev2023Achieved
//       jev2023NotAchieved
//       jev2024NotEndorsed
//       jev2024ReEstablished
//       jev2024NewlyAchieved
//       jev2024Achieved
//       jev2024NotAchieved
//       jev2025NotEndorsed
//       jev2025ReEstablished
//       jev2025NewlyAchieved
//       jev2025Achieved
//       jev2025NotAchieved
//       jev2026NotEndorsed
//       jev2026ReEstablished
//       jev2026NewlyAchieved
//       jev2026Achieved
//       jev2026NotAchieved
//       jev2027NotEndorsed
//       jev2027ReEstablished
//       jev2027NewlyAchieved
//       jev2027Achieved
//       jev2027NotAchieved
//       jev2028NotEndorsed
//       jev2028ReEstablished
//       jev2028NewlyAchieved
//       jev2028Achieved
//       jev2028NotAchieved
//       jev2029NotEndorsed
//       jev2029ReEstablished
//       jev2029NewlyAchieved
//       jev2029Achieved
//       jev2029NotAchieved
//       jev2030NotEndorsed
//       jev2030ReEstablished
//       jev2030NewlyAchieved
//       jev2030Achieved
//       jev2030NotAchieved

//       hpv2020NotEndorsed
//       hpv2020ReEstablished
//       hpv2020NewlyAchieved
//       hpv2020Achieved
//       hpv2020NotAchieved
//       hpv2021NotEndorsed
//       hpv2021ReEstablished
//       hpv2021NewlyAchieved
//       hpv2021Achieved
//       hpv2021NotAchieved
//       hpv2022NotEndorsed
//       hpv2022ReEstablished
//       hpv2022NewlyAchieved
//       hpv2022Achieved
//       hpv2022NotAchieved
//       hpv2023NotEndorsed
//       hpv2023ReEstablished
//       hpv2023NewlyAchieved
//       hpv2023Achieved
//       hpv2023NotAchieved
//       hpv2024NotEndorsed
//       hpv2024ReEstablished
//       hpv2024NewlyAchieved
//       hpv2024Achieved
//       hpv2024NotAchieved
//       hpv2025NotEndorsed
//       hpv2025ReEstablished
//       hpv2025NewlyAchieved
//       hpv2025Achieved
//       hpv2025NotAchieved
//       hpv2026NotEndorsed
//       hpv2026ReEstablished
//       hpv2026NewlyAchieved
//       hpv2026Achieved
//       hpv2026NotAchieved
//       hpv2027NotEndorsed
//       hpv2027ReEstablished
//       hpv2027NewlyAchieved
//       hpv2027Achieved
//       hpv2027NotAchieved
//       hpv2028NotEndorsed
//       hpv2028ReEstablished
//       hpv2028NewlyAchieved
//       hpv2028Achieved
//       hpv2028NotAchieved
//       hpv2029NotEndorsed
//       hpv2029ReEstablished
//       hpv2029NewlyAchieved
//       hpv2029Achieved
//       hpv2029NotAchieved
//       hpv2030NotEndorsed
//       hpv2030ReEstablished
//       hpv2030NewlyAchieved
//       hpv2030Achieved
//       hpv2030NotAchieved

//       tetanus2020NotEndorsed
//       tetanus2020ReEstablished
//       tetanus2020NewlyAchieved
//       tetanus2020Achieved
//       tetanus2020NotAchieved
//       tetanus2021NotEndorsed
//       tetanus2021ReEstablished
//       tetanus2021NewlyAchieved
//       tetanus2021Achieved
//       tetanus2021NotAchieved
//       tetanus2022NotEndorsed
//       tetanus2022ReEstablished
//       tetanus2022NewlyAchieved
//       tetanus2022Achieved
//       tetanus2022NotAchieved
//       tetanus2023NotEndorsed
//       tetanus2023ReEstablished
//       tetanus2023NewlyAchieved
//       tetanus2023Achieved
//       tetanus2023NotAchieved
//       tetanus2024NotEndorsed
//       tetanus2024ReEstablished
//       tetanus2024NewlyAchieved
//       tetanus2024Achieved
//       tetanus2024NotAchieved
//       tetanus2025NotEndorsed
//       tetanus2025ReEstablished
//       tetanus2025NewlyAchieved
//       tetanus2025Achieved
//       tetanus2025NotAchieved
//       tetanus2026NotEndorsed
//       tetanus2026ReEstablished
//       tetanus2026NewlyAchieved
//       tetanus2026Achieved
//       tetanus2026NotAchieved
//       tetanus2027NotEndorsed
//       tetanus2027ReEstablished
//       tetanus2027NewlyAchieved
//       tetanus2027Achieved
//       tetanus2027NotAchieved
//       tetanus2028NotEndorsed
//       tetanus2028ReEstablished
//       tetanus2028NewlyAchieved
//       tetanus2028Achieved
//       tetanus2028NotAchieved
//       tetanus2029NotEndorsed
//       tetanus2029ReEstablished
//       tetanus2029NewlyAchieved
//       tetanus2029Achieved
//       tetanus2029NotAchieved
//       tetanus2030NotEndorsed
//       tetanus2030ReEstablished
//       tetanus2030NewlyAchieved
//       tetanus2030Achieved
//       tetanus2030NotAchieved

//       hepb2020NotEndorsed
//       hepb2020ReEstablished
//       hepb2020NewlyAchieved
//       hepb2020Achieved
//       hepb2020NotAchieved
//       hepb2021NotEndorsed
//       hepb2021ReEstablished
//       hepb2021NewlyAchieved
//       hepb2021Achieved
//       hepb2021NotAchieved
//       hepb2022NotEndorsed
//       hepb2022ReEstablished
//       hepb2022NewlyAchieved
//       hepb2022Achieved
//       hepb2022NotAchieved
//       hepb2023NotEndorsed
//       hepb2023ReEstablished
//       hepb2023NewlyAchieved
//       hepb2023Achieved
//       hepb2023NotAchieved
//       hepb2024NotEndorsed
//       hepb2024ReEstablished
//       hepb2024NewlyAchieved
//       hepb2024Achieved
//       hepb2024NotAchieved
//       hepb2025NotEndorsed
//       hepb2025ReEstablished
//       hepb2025NewlyAchieved
//       hepb2025Achieved
//       hepb2025NotAchieved
//       hepb2026NotEndorsed
//       hepb2026ReEstablished
//       hepb2026NewlyAchieved
//       hepb2026Achieved
//       hepb2026NotAchieved
//       hepb2027NotEndorsed
//       hepb2027ReEstablished
//       hepb2027NewlyAchieved
//       hepb2027Achieved
//       hepb2027NotAchieved
//       hepb2028NotEndorsed
//       hepb2028ReEstablished
//       hepb2028NewlyAchieved
//       hepb2028Achieved
//       hepb2028NotAchieved
//       hepb2029NotEndorsed
//       hepb2029ReEstablished
//       hepb2029NewlyAchieved
//       hepb2029Achieved
//       hepb2029NotAchieved
//       hepb2030NotEndorsed
//       hepb2030ReEstablished
//       hepb2030NewlyAchieved
//       hepb2030Achieved
//       hepb2030NotAchieved

//       mNT2020NotEndorsed
//       mNT2020ReEstablished
//       mNT2020NewlyAchieved
//       mNT2020Achieved
//       mNT2020NotAchieved
//       mNT2021NotEndorsed
//       mNT2021ReEstablished
//       mNT2021NewlyAchieved
//       mNT2021Achieved
//       mNT2021NotAchieved
//       mNT2022NotEndorsed
//       mNT2022ReEstablished
//       mNT2022NewlyAchieved
//       mNT2022Achieved
//       mNT2022NotAchieved
//       mNT2023NotEndorsed
//       mNT2023ReEstablished
//       mNT2023NewlyAchieved
//       mNT2023Achieved
//       mNT2023NotAchieved
//       mNT2024NotEndorsed
//       mNT2024ReEstablished
//       mNT2024NewlyAchieved
//       mNT2024Achieved
//       mNT2024NotAchieved
//       mNT2025NotEndorsed
//       mNT2025ReEstablished
//       mNT2025NewlyAchieved
//       mNT2025Achieved
//       mNT2025NotAchieved
//       mNT2026NotEndorsed
//       mNT2026ReEstablished
//       mNT2026NewlyAchieved
//       mNT2026Achieved
//       mNT2026NotAchieved
//       mNT2027NotEndorsed
//       mNT2027ReEstablished
//       mNT2027NewlyAchieved
//       mNT2027Achieved
//       mNT2027NotAchieved
//       mNT2028NotEndorsed
//       mNT2028ReEstablished
//       mNT2028NewlyAchieved
//       mNT2028Achieved
//       mNT2028NotAchieved
//       mNT2029NotEndorsed
//       mNT2029ReEstablished
//       mNT2029NewlyAchieved
//       mNT2029Achieved
//       mNT2029NotAchieved
//       mNT2030NotEndorsed
//       mNT2030ReEstablished
//       mNT2030NewlyAchieved
//       mNT2030Achieved
//       mNT2030NotAchieved
//     }
//     regions: allGoogleIg12Sheet(filter: { regionType: { eq: "REG" } }) {
//       nodes {
//         id
//         memberState
//         iSO3
//         regionType
//         region
//         measles2020NotEndorsed
//         measles2020ReEstablished
//         measles2020NewlyAchieved
//         measles2020Achieved
//         measles2020NotAchieved
//         measles2021NotEndorsed
//         measles2021ReEstablished
//         measles2021NewlyAchieved
//         measles2021Achieved
//         measles2021NotAchieved
//         measles2022NotEndorsed
//         measles2022ReEstablished
//         measles2022NewlyAchieved
//         measles2022Achieved
//         measles2022NotAchieved
//         measles2023NotEndorsed
//         measles2023ReEstablished
//         measles2023NewlyAchieved
//         measles2023Achieved
//         measles2023NotAchieved
//         measles2024NotEndorsed
//         measles2024ReEstablished
//         measles2024NewlyAchieved
//         measles2024Achieved
//         measles2024NotAchieved
//         measles2025NotEndorsed
//         measles2025ReEstablished
//         measles2025NewlyAchieved
//         measles2025Achieved
//         measles2025NotAchieved
//         measles2026NotEndorsed
//         measles2026ReEstablished
//         measles2026NewlyAchieved
//         measles2026Achieved
//         measles2026NotAchieved
//         measles2027NotEndorsed
//         measles2027ReEstablished
//         measles2027NewlyAchieved
//         measles2027Achieved
//         measles2027NotAchieved
//         measles2028NotEndorsed
//         measles2028ReEstablished
//         measles2028NewlyAchieved
//         measles2028Achieved
//         measles2028NotAchieved
//         measles2029NotEndorsed
//         measles2029ReEstablished
//         measles2029NewlyAchieved
//         measles2029Achieved
//         measles2029NotAchieved
//         measles2030NotEndorsed
//         measles2030ReEstablished
//         measles2030NewlyAchieved
//         measles2030Achieved
//         measles2030NotAchieved

//         rubella2020NotEndorsed
//         rubella2020ReEstablished
//         rubella2020NewlyAchieved
//         rubella2020Achieved
//         rubella2020NotAchieved
//         rubella2021NotEndorsed
//         rubella2021ReEstablished
//         rubella2021NewlyAchieved
//         rubella2021Achieved
//         rubella2021NotAchieved
//         rubella2022NotEndorsed
//         rubella2022ReEstablished
//         rubella2022NewlyAchieved
//         rubella2022Achieved
//         rubella2022NotAchieved
//         rubella2023NotEndorsed
//         rubella2023ReEstablished
//         rubella2023NewlyAchieved
//         rubella2023Achieved
//         rubella2023NotAchieved
//         rubella2024NotEndorsed
//         rubella2024ReEstablished
//         rubella2024NewlyAchieved
//         rubella2024Achieved
//         rubella2024NotAchieved
//         rubella2025NotEndorsed
//         rubella2025ReEstablished
//         rubella2025NewlyAchieved
//         rubella2025Achieved
//         rubella2025NotAchieved
//         rubella2026NotEndorsed
//         rubella2026ReEstablished
//         rubella2026NewlyAchieved
//         rubella2026Achieved
//         rubella2026NotAchieved
//         rubella2027NotEndorsed
//         rubella2027ReEstablished
//         rubella2027NewlyAchieved
//         rubella2027Achieved
//         rubella2027NotAchieved
//         rubella2028NotEndorsed
//         rubella2028ReEstablished
//         rubella2028NewlyAchieved
//         rubella2028Achieved
//         rubella2028NotAchieved
//         rubella2029NotEndorsed
//         rubella2029ReEstablished
//         rubella2029NewlyAchieved
//         rubella2029Achieved
//         rubella2029NotAchieved
//         rubella2030NotEndorsed
//         rubella2030ReEstablished
//         rubella2030NewlyAchieved
//         rubella2030Achieved
//         rubella2030NotAchieved

//         wPV2020NotEndorsed
//         wPV2020ReEstablished
//         wPV2020NewlyAchieved
//         wPV2020Achieved
//         wPV2020NotAchieved
//         wPV2021NotEndorsed
//         wPV2021ReEstablished
//         wPV2021NewlyAchieved
//         wPV2021Achieved
//         wPV2021NotAchieved
//         wPV2022NotEndorsed
//         wPV2022ReEstablished
//         wPV2022NewlyAchieved
//         wPV2022Achieved
//         wPV2022NotAchieved
//         wPV2023NotEndorsed
//         wPV2023ReEstablished
//         wPV2023NewlyAchieved
//         wPV2023Achieved
//         wPV2023NotAchieved
//         wPV2024NotEndorsed
//         wPV2024ReEstablished
//         wPV2024NewlyAchieved
//         wPV2024Achieved
//         wPV2024NotAchieved
//         wPV2025NotEndorsed
//         wPV2025ReEstablished
//         wPV2025NewlyAchieved
//         wPV2025Achieved
//         wPV2025NotAchieved
//         wPV2026NotEndorsed
//         wPV2026ReEstablished
//         wPV2026NewlyAchieved
//         wPV2026Achieved
//         wPV2026NotAchieved
//         wPV2027NotEndorsed
//         wPV2027ReEstablished
//         wPV2027NewlyAchieved
//         wPV2027Achieved
//         wPV2027NotAchieved
//         wPV2028NotEndorsed
//         wPV2028ReEstablished
//         wPV2028NewlyAchieved
//         wPV2028Achieved
//         wPV2028NotAchieved
//         wPV2029NotEndorsed
//         wPV2029ReEstablished
//         wPV2029NewlyAchieved
//         wPV2029Achieved
//         wPV2029NotAchieved
//         wPV2030NotEndorsed
//         wPV2030ReEstablished
//         wPV2030NewlyAchieved
//         wPV2030Achieved
//         wPV2030NotAchieved

//         jev2020NotEndorsed
//         jev2020ReEstablished
//         jev2020NewlyAchieved
//         jev2020Achieved
//         jev2020NotAchieved
//         jev2021NotEndorsed
//         jev2021ReEstablished
//         jev2021NewlyAchieved
//         jev2021Achieved
//         jev2021NotAchieved
//         jev2022NotEndorsed
//         jev2022ReEstablished
//         jev2022NewlyAchieved
//         jev2022Achieved
//         jev2022NotAchieved
//         jev2023NotEndorsed
//         jev2023ReEstablished
//         jev2023NewlyAchieved
//         jev2023Achieved
//         jev2023NotAchieved
//         jev2024NotEndorsed
//         jev2024ReEstablished
//         jev2024NewlyAchieved
//         jev2024Achieved
//         jev2024NotAchieved
//         jev2025NotEndorsed
//         jev2025ReEstablished
//         jev2025NewlyAchieved
//         jev2025Achieved
//         jev2025NotAchieved
//         jev2026NotEndorsed
//         jev2026ReEstablished
//         jev2026NewlyAchieved
//         jev2026Achieved
//         jev2026NotAchieved
//         jev2027NotEndorsed
//         jev2027ReEstablished
//         jev2027NewlyAchieved
//         jev2027Achieved
//         jev2027NotAchieved
//         jev2028NotEndorsed
//         jev2028ReEstablished
//         jev2028NewlyAchieved
//         jev2028Achieved
//         jev2028NotAchieved
//         jev2029NotEndorsed
//         jev2029ReEstablished
//         jev2029NewlyAchieved
//         jev2029Achieved
//         jev2029NotAchieved
//         jev2030NotEndorsed
//         jev2030ReEstablished
//         jev2030NewlyAchieved
//         jev2030Achieved
//         jev2030NotAchieved

//         hpv2020NotEndorsed
//         hpv2020ReEstablished
//         hpv2020NewlyAchieved
//         hpv2020Achieved
//         hpv2020NotAchieved
//         hpv2021NotEndorsed
//         hpv2021ReEstablished
//         hpv2021NewlyAchieved
//         hpv2021Achieved
//         hpv2021NotAchieved
//         hpv2022NotEndorsed
//         hpv2022ReEstablished
//         hpv2022NewlyAchieved
//         hpv2022Achieved
//         hpv2022NotAchieved
//         hpv2023NotEndorsed
//         hpv2023ReEstablished
//         hpv2023NewlyAchieved
//         hpv2023Achieved
//         hpv2023NotAchieved
//         hpv2024NotEndorsed
//         hpv2024ReEstablished
//         hpv2024NewlyAchieved
//         hpv2024Achieved
//         hpv2024NotAchieved
//         hpv2025NotEndorsed
//         hpv2025ReEstablished
//         hpv2025NewlyAchieved
//         hpv2025Achieved
//         hpv2025NotAchieved
//         hpv2026NotEndorsed
//         hpv2026ReEstablished
//         hpv2026NewlyAchieved
//         hpv2026Achieved
//         hpv2026NotAchieved
//         hpv2027NotEndorsed
//         hpv2027ReEstablished
//         hpv2027NewlyAchieved
//         hpv2027Achieved
//         hpv2027NotAchieved
//         hpv2028NotEndorsed
//         hpv2028ReEstablished
//         hpv2028NewlyAchieved
//         hpv2028Achieved
//         hpv2028NotAchieved
//         hpv2029NotEndorsed
//         hpv2029ReEstablished
//         hpv2029NewlyAchieved
//         hpv2029Achieved
//         hpv2029NotAchieved
//         hpv2030NotEndorsed
//         hpv2030ReEstablished
//         hpv2030NewlyAchieved
//         hpv2030Achieved
//         hpv2030NotAchieved

//         tetanus2020NotEndorsed
//         tetanus2020ReEstablished
//         tetanus2020NewlyAchieved
//         tetanus2020Achieved
//         tetanus2020NotAchieved
//         tetanus2021NotEndorsed
//         tetanus2021ReEstablished
//         tetanus2021NewlyAchieved
//         tetanus2021Achieved
//         tetanus2021NotAchieved
//         tetanus2022NotEndorsed
//         tetanus2022ReEstablished
//         tetanus2022NewlyAchieved
//         tetanus2022Achieved
//         tetanus2022NotAchieved
//         tetanus2023NotEndorsed
//         tetanus2023ReEstablished
//         tetanus2023NewlyAchieved
//         tetanus2023Achieved
//         tetanus2023NotAchieved
//         tetanus2024NotEndorsed
//         tetanus2024ReEstablished
//         tetanus2024NewlyAchieved
//         tetanus2024Achieved
//         tetanus2024NotAchieved
//         tetanus2025NotEndorsed
//         tetanus2025ReEstablished
//         tetanus2025NewlyAchieved
//         tetanus2025Achieved
//         tetanus2025NotAchieved
//         tetanus2026NotEndorsed
//         tetanus2026ReEstablished
//         tetanus2026NewlyAchieved
//         tetanus2026Achieved
//         tetanus2026NotAchieved
//         tetanus2027NotEndorsed
//         tetanus2027ReEstablished
//         tetanus2027NewlyAchieved
//         tetanus2027Achieved
//         tetanus2027NotAchieved
//         tetanus2028NotEndorsed
//         tetanus2028ReEstablished
//         tetanus2028NewlyAchieved
//         tetanus2028Achieved
//         tetanus2028NotAchieved
//         tetanus2029NotEndorsed
//         tetanus2029ReEstablished
//         tetanus2029NewlyAchieved
//         tetanus2029Achieved
//         tetanus2029NotAchieved
//         tetanus2030NotEndorsed
//         tetanus2030ReEstablished
//         tetanus2030NewlyAchieved
//         tetanus2030Achieved
//         tetanus2030NotAchieved

//         hepb2020NotEndorsed
//         hepb2020ReEstablished
//         hepb2020NewlyAchieved
//         hepb2020Achieved
//         hepb2020NotAchieved
//         hepb2021NotEndorsed
//         hepb2021ReEstablished
//         hepb2021NewlyAchieved
//         hepb2021Achieved
//         hepb2021NotAchieved
//         hepb2022NotEndorsed
//         hepb2022ReEstablished
//         hepb2022NewlyAchieved
//         hepb2022Achieved
//         hepb2022NotAchieved
//         hepb2023NotEndorsed
//         hepb2023ReEstablished
//         hepb2023NewlyAchieved
//         hepb2023Achieved
//         hepb2023NotAchieved
//         hepb2024NotEndorsed
//         hepb2024ReEstablished
//         hepb2024NewlyAchieved
//         hepb2024Achieved
//         hepb2024NotAchieved
//         hepb2025NotEndorsed
//         hepb2025ReEstablished
//         hepb2025NewlyAchieved
//         hepb2025Achieved
//         hepb2025NotAchieved
//         hepb2026NotEndorsed
//         hepb2026ReEstablished
//         hepb2026NewlyAchieved
//         hepb2026Achieved
//         hepb2026NotAchieved
//         hepb2027NotEndorsed
//         hepb2027ReEstablished
//         hepb2027NewlyAchieved
//         hepb2027Achieved
//         hepb2027NotAchieved
//         hepb2028NotEndorsed
//         hepb2028ReEstablished
//         hepb2028NewlyAchieved
//         hepb2028Achieved
//         hepb2028NotAchieved
//         hepb2029NotEndorsed
//         hepb2029ReEstablished
//         hepb2029NewlyAchieved
//         hepb2029Achieved
//         hepb2029NotAchieved
//         hepb2030NotEndorsed
//         hepb2030ReEstablished
//         hepb2030NewlyAchieved
//         hepb2030Achieved
//         hepb2030NotAchieved

//         mNT2020NotEndorsed
//         mNT2020ReEstablished
//         mNT2020NewlyAchieved
//         mNT2020Achieved
//         mNT2020NotAchieved
//         mNT2021NotEndorsed
//         mNT2021ReEstablished
//         mNT2021NewlyAchieved
//         mNT2021Achieved
//         mNT2021NotAchieved
//         mNT2022NotEndorsed
//         mNT2022ReEstablished
//         mNT2022NewlyAchieved
//         mNT2022Achieved
//         mNT2022NotAchieved
//         mNT2023NotEndorsed
//         mNT2023ReEstablished
//         mNT2023NewlyAchieved
//         mNT2023Achieved
//         mNT2023NotAchieved
//         mNT2024NotEndorsed
//         mNT2024ReEstablished
//         mNT2024NewlyAchieved
//         mNT2024Achieved
//         mNT2024NotAchieved
//         mNT2025NotEndorsed
//         mNT2025ReEstablished
//         mNT2025NewlyAchieved
//         mNT2025Achieved
//         mNT2025NotAchieved
//         mNT2026NotEndorsed
//         mNT2026ReEstablished
//         mNT2026NewlyAchieved
//         mNT2026Achieved
//         mNT2026NotAchieved
//         mNT2027NotEndorsed
//         mNT2027ReEstablished
//         mNT2027NewlyAchieved
//         mNT2027Achieved
//         mNT2027NotAchieved
//         mNT2028NotEndorsed
//         mNT2028ReEstablished
//         mNT2028NewlyAchieved
//         mNT2028Achieved
//         mNT2028NotAchieved
//         mNT2029NotEndorsed
//         mNT2029ReEstablished
//         mNT2029NewlyAchieved
//         mNT2029Achieved
//         mNT2029NotAchieved
//         mNT2030NotEndorsed
//         mNT2030ReEstablished
//         mNT2030NewlyAchieved
//         mNT2030Achieved
//         mNT2030NotAchieved
//       }
//     }
//     income: allGoogleIg12Sheet(filter: { regionType: { eq: "INC" } }) {
//       nodes {
//         id
//         memberState
//         iSO3
//         regionType
//         incomeGroup
//         measles2020NotEndorsed
//         measles2020ReEstablished
//         measles2020NewlyAchieved
//         measles2020Achieved
//         measles2020NotAchieved
//         measles2021NotEndorsed
//         measles2021ReEstablished
//         measles2021NewlyAchieved
//         measles2021Achieved
//         measles2021NotAchieved
//         measles2022NotEndorsed
//         measles2022ReEstablished
//         measles2022NewlyAchieved
//         measles2022Achieved
//         measles2022NotAchieved
//         measles2023NotEndorsed
//         measles2023ReEstablished
//         measles2023NewlyAchieved
//         measles2023Achieved
//         measles2023NotAchieved
//         measles2024NotEndorsed
//         measles2024ReEstablished
//         measles2024NewlyAchieved
//         measles2024Achieved
//         measles2024NotAchieved
//         measles2025NotEndorsed
//         measles2025ReEstablished
//         measles2025NewlyAchieved
//         measles2025Achieved
//         measles2025NotAchieved
//         measles2026NotEndorsed
//         measles2026ReEstablished
//         measles2026NewlyAchieved
//         measles2026Achieved
//         measles2026NotAchieved
//         measles2027NotEndorsed
//         measles2027ReEstablished
//         measles2027NewlyAchieved
//         measles2027Achieved
//         measles2027NotAchieved
//         measles2028NotEndorsed
//         measles2028ReEstablished
//         measles2028NewlyAchieved
//         measles2028Achieved
//         measles2028NotAchieved
//         measles2029NotEndorsed
//         measles2029ReEstablished
//         measles2029NewlyAchieved
//         measles2029Achieved
//         measles2029NotAchieved
//         measles2030NotEndorsed
//         measles2030ReEstablished
//         measles2030NewlyAchieved
//         measles2030Achieved
//         measles2030NotAchieved

//         rubella2020NotEndorsed
//         rubella2020ReEstablished
//         rubella2020NewlyAchieved
//         rubella2020Achieved
//         rubella2020NotAchieved
//         rubella2021NotEndorsed
//         rubella2021ReEstablished
//         rubella2021NewlyAchieved
//         rubella2021Achieved
//         rubella2021NotAchieved
//         rubella2022NotEndorsed
//         rubella2022ReEstablished
//         rubella2022NewlyAchieved
//         rubella2022Achieved
//         rubella2022NotAchieved
//         rubella2023NotEndorsed
//         rubella2023ReEstablished
//         rubella2023NewlyAchieved
//         rubella2023Achieved
//         rubella2023NotAchieved
//         rubella2024NotEndorsed
//         rubella2024ReEstablished
//         rubella2024NewlyAchieved
//         rubella2024Achieved
//         rubella2024NotAchieved
//         rubella2025NotEndorsed
//         rubella2025ReEstablished
//         rubella2025NewlyAchieved
//         rubella2025Achieved
//         rubella2025NotAchieved
//         rubella2026NotEndorsed
//         rubella2026ReEstablished
//         rubella2026NewlyAchieved
//         rubella2026Achieved
//         rubella2026NotAchieved
//         rubella2027NotEndorsed
//         rubella2027ReEstablished
//         rubella2027NewlyAchieved
//         rubella2027Achieved
//         rubella2027NotAchieved
//         rubella2028NotEndorsed
//         rubella2028ReEstablished
//         rubella2028NewlyAchieved
//         rubella2028Achieved
//         rubella2028NotAchieved
//         rubella2029NotEndorsed
//         rubella2029ReEstablished
//         rubella2029NewlyAchieved
//         rubella2029Achieved
//         rubella2029NotAchieved
//         rubella2030NotEndorsed
//         rubella2030ReEstablished
//         rubella2030NewlyAchieved
//         rubella2030Achieved
//         rubella2030NotAchieved

//         wPV2020NotEndorsed
//         wPV2020ReEstablished
//         wPV2020NewlyAchieved
//         wPV2020Achieved
//         wPV2020NotAchieved
//         wPV2021NotEndorsed
//         wPV2021ReEstablished
//         wPV2021NewlyAchieved
//         wPV2021Achieved
//         wPV2021NotAchieved
//         wPV2022NotEndorsed
//         wPV2022ReEstablished
//         wPV2022NewlyAchieved
//         wPV2022Achieved
//         wPV2022NotAchieved
//         wPV2023NotEndorsed
//         wPV2023ReEstablished
//         wPV2023NewlyAchieved
//         wPV2023Achieved
//         wPV2023NotAchieved
//         wPV2024NotEndorsed
//         wPV2024ReEstablished
//         wPV2024NewlyAchieved
//         wPV2024Achieved
//         wPV2024NotAchieved
//         wPV2025NotEndorsed
//         wPV2025ReEstablished
//         wPV2025NewlyAchieved
//         wPV2025Achieved
//         wPV2025NotAchieved
//         wPV2026NotEndorsed
//         wPV2026ReEstablished
//         wPV2026NewlyAchieved
//         wPV2026Achieved
//         wPV2026NotAchieved
//         wPV2027NotEndorsed
//         wPV2027ReEstablished
//         wPV2027NewlyAchieved
//         wPV2027Achieved
//         wPV2027NotAchieved
//         wPV2028NotEndorsed
//         wPV2028ReEstablished
//         wPV2028NewlyAchieved
//         wPV2028Achieved
//         wPV2028NotAchieved
//         wPV2029NotEndorsed
//         wPV2029ReEstablished
//         wPV2029NewlyAchieved
//         wPV2029Achieved
//         wPV2029NotAchieved
//         wPV2030NotEndorsed
//         wPV2030ReEstablished
//         wPV2030NewlyAchieved
//         wPV2030Achieved
//         wPV2030NotAchieved

//         jev2020NotEndorsed
//         jev2020ReEstablished
//         jev2020NewlyAchieved
//         jev2020Achieved
//         jev2020NotAchieved
//         jev2021NotEndorsed
//         jev2021ReEstablished
//         jev2021NewlyAchieved
//         jev2021Achieved
//         jev2021NotAchieved
//         jev2022NotEndorsed
//         jev2022ReEstablished
//         jev2022NewlyAchieved
//         jev2022Achieved
//         jev2022NotAchieved
//         jev2023NotEndorsed
//         jev2023ReEstablished
//         jev2023NewlyAchieved
//         jev2023Achieved
//         jev2023NotAchieved
//         jev2024NotEndorsed
//         jev2024ReEstablished
//         jev2024NewlyAchieved
//         jev2024Achieved
//         jev2024NotAchieved
//         jev2025NotEndorsed
//         jev2025ReEstablished
//         jev2025NewlyAchieved
//         jev2025Achieved
//         jev2025NotAchieved
//         jev2026NotEndorsed
//         jev2026ReEstablished
//         jev2026NewlyAchieved
//         jev2026Achieved
//         jev2026NotAchieved
//         jev2027NotEndorsed
//         jev2027ReEstablished
//         jev2027NewlyAchieved
//         jev2027Achieved
//         jev2027NotAchieved
//         jev2028NotEndorsed
//         jev2028ReEstablished
//         jev2028NewlyAchieved
//         jev2028Achieved
//         jev2028NotAchieved
//         jev2029NotEndorsed
//         jev2029ReEstablished
//         jev2029NewlyAchieved
//         jev2029Achieved
//         jev2029NotAchieved
//         jev2030NotEndorsed
//         jev2030ReEstablished
//         jev2030NewlyAchieved
//         jev2030Achieved
//         jev2030NotAchieved

//         hpv2020NotEndorsed
//         hpv2020ReEstablished
//         hpv2020NewlyAchieved
//         hpv2020Achieved
//         hpv2020NotAchieved
//         hpv2021NotEndorsed
//         hpv2021ReEstablished
//         hpv2021NewlyAchieved
//         hpv2021Achieved
//         hpv2021NotAchieved
//         hpv2022NotEndorsed
//         hpv2022ReEstablished
//         hpv2022NewlyAchieved
//         hpv2022Achieved
//         hpv2022NotAchieved
//         hpv2023NotEndorsed
//         hpv2023ReEstablished
//         hpv2023NewlyAchieved
//         hpv2023Achieved
//         hpv2023NotAchieved
//         hpv2024NotEndorsed
//         hpv2024ReEstablished
//         hpv2024NewlyAchieved
//         hpv2024Achieved
//         hpv2024NotAchieved
//         hpv2025NotEndorsed
//         hpv2025ReEstablished
//         hpv2025NewlyAchieved
//         hpv2025Achieved
//         hpv2025NotAchieved
//         hpv2026NotEndorsed
//         hpv2026ReEstablished
//         hpv2026NewlyAchieved
//         hpv2026Achieved
//         hpv2026NotAchieved
//         hpv2027NotEndorsed
//         hpv2027ReEstablished
//         hpv2027NewlyAchieved
//         hpv2027Achieved
//         hpv2027NotAchieved
//         hpv2028NotEndorsed
//         hpv2028ReEstablished
//         hpv2028NewlyAchieved
//         hpv2028Achieved
//         hpv2028NotAchieved
//         hpv2029NotEndorsed
//         hpv2029ReEstablished
//         hpv2029NewlyAchieved
//         hpv2029Achieved
//         hpv2029NotAchieved
//         hpv2030NotEndorsed
//         hpv2030ReEstablished
//         hpv2030NewlyAchieved
//         hpv2030Achieved
//         hpv2030NotAchieved

//         tetanus2020NotEndorsed
//         tetanus2020ReEstablished
//         tetanus2020NewlyAchieved
//         tetanus2020Achieved
//         tetanus2020NotAchieved
//         tetanus2021NotEndorsed
//         tetanus2021ReEstablished
//         tetanus2021NewlyAchieved
//         tetanus2021Achieved
//         tetanus2021NotAchieved
//         tetanus2022NotEndorsed
//         tetanus2022ReEstablished
//         tetanus2022NewlyAchieved
//         tetanus2022Achieved
//         tetanus2022NotAchieved
//         tetanus2023NotEndorsed
//         tetanus2023ReEstablished
//         tetanus2023NewlyAchieved
//         tetanus2023Achieved
//         tetanus2023NotAchieved
//         tetanus2024NotEndorsed
//         tetanus2024ReEstablished
//         tetanus2024NewlyAchieved
//         tetanus2024Achieved
//         tetanus2024NotAchieved
//         tetanus2025NotEndorsed
//         tetanus2025ReEstablished
//         tetanus2025NewlyAchieved
//         tetanus2025Achieved
//         tetanus2025NotAchieved
//         tetanus2026NotEndorsed
//         tetanus2026ReEstablished
//         tetanus2026NewlyAchieved
//         tetanus2026Achieved
//         tetanus2026NotAchieved
//         tetanus2027NotEndorsed
//         tetanus2027ReEstablished
//         tetanus2027NewlyAchieved
//         tetanus2027Achieved
//         tetanus2027NotAchieved
//         tetanus2028NotEndorsed
//         tetanus2028ReEstablished
//         tetanus2028NewlyAchieved
//         tetanus2028Achieved
//         tetanus2028NotAchieved
//         tetanus2029NotEndorsed
//         tetanus2029ReEstablished
//         tetanus2029NewlyAchieved
//         tetanus2029Achieved
//         tetanus2029NotAchieved
//         tetanus2030NotEndorsed
//         tetanus2030ReEstablished
//         tetanus2030NewlyAchieved
//         tetanus2030Achieved
//         tetanus2030NotAchieved

//         hepb2020NotEndorsed
//         hepb2020ReEstablished
//         hepb2020NewlyAchieved
//         hepb2020Achieved
//         hepb2020NotAchieved
//         hepb2021NotEndorsed
//         hepb2021ReEstablished
//         hepb2021NewlyAchieved
//         hepb2021Achieved
//         hepb2021NotAchieved
//         hepb2022NotEndorsed
//         hepb2022ReEstablished
//         hepb2022NewlyAchieved
//         hepb2022Achieved
//         hepb2022NotAchieved
//         hepb2023NotEndorsed
//         hepb2023ReEstablished
//         hepb2023NewlyAchieved
//         hepb2023Achieved
//         hepb2023NotAchieved
//         hepb2024NotEndorsed
//         hepb2024ReEstablished
//         hepb2024NewlyAchieved
//         hepb2024Achieved
//         hepb2024NotAchieved
//         hepb2025NotEndorsed
//         hepb2025ReEstablished
//         hepb2025NewlyAchieved
//         hepb2025Achieved
//         hepb2025NotAchieved
//         hepb2026NotEndorsed
//         hepb2026ReEstablished
//         hepb2026NewlyAchieved
//         hepb2026Achieved
//         hepb2026NotAchieved
//         hepb2027NotEndorsed
//         hepb2027ReEstablished
//         hepb2027NewlyAchieved
//         hepb2027Achieved
//         hepb2027NotAchieved
//         hepb2028NotEndorsed
//         hepb2028ReEstablished
//         hepb2028NewlyAchieved
//         hepb2028Achieved
//         hepb2028NotAchieved
//         hepb2029NotEndorsed
//         hepb2029ReEstablished
//         hepb2029NewlyAchieved
//         hepb2029Achieved
//         hepb2029NotAchieved
//         hepb2030NotEndorsed
//         hepb2030ReEstablished
//         hepb2030NewlyAchieved
//         hepb2030Achieved
//         hepb2030NotAchieved

//         mNT2020NotEndorsed
//         mNT2020ReEstablished
//         mNT2020NewlyAchieved
//         mNT2020Achieved
//         mNT2020NotAchieved
//         mNT2021NotEndorsed
//         mNT2021ReEstablished
//         mNT2021NewlyAchieved
//         mNT2021Achieved
//         mNT2021NotAchieved
//         mNT2022NotEndorsed
//         mNT2022ReEstablished
//         mNT2022NewlyAchieved
//         mNT2022Achieved
//         mNT2022NotAchieved
//         mNT2023NotEndorsed
//         mNT2023ReEstablished
//         mNT2023NewlyAchieved
//         mNT2023Achieved
//         mNT2023NotAchieved
//         mNT2024NotEndorsed
//         mNT2024ReEstablished
//         mNT2024NewlyAchieved
//         mNT2024Achieved
//         mNT2024NotAchieved
//         mNT2025NotEndorsed
//         mNT2025ReEstablished
//         mNT2025NewlyAchieved
//         mNT2025Achieved
//         mNT2025NotAchieved
//         mNT2026NotEndorsed
//         mNT2026ReEstablished
//         mNT2026NewlyAchieved
//         mNT2026Achieved
//         mNT2026NotAchieved
//         mNT2027NotEndorsed
//         mNT2027ReEstablished
//         mNT2027NewlyAchieved
//         mNT2027Achieved
//         mNT2027NotAchieved
//         mNT2028NotEndorsed
//         mNT2028ReEstablished
//         mNT2028NewlyAchieved
//         mNT2028Achieved
//         mNT2028NotAchieved
//         mNT2029NotEndorsed
//         mNT2029ReEstablished
//         mNT2029NewlyAchieved
//         mNT2029Achieved
//         mNT2029NotAchieved
//         mNT2030NotEndorsed
//         mNT2030ReEstablished
//         mNT2030NewlyAchieved
//         mNT2030Achieved
//         mNT2030NotAchieved
//       }
//     }
//     unicef: allGoogleIg12Sheet(filter: { regionType: { eq: "UNI" } }) {
//       nodes {
//         id
//         memberState
//         iSO3
//         regionType
//         uNICEFRegion
//         measles2020NotEndorsed
//         measles2020ReEstablished
//         measles2020NewlyAchieved
//         measles2020Achieved
//         measles2020NotAchieved
//         measles2021NotEndorsed
//         measles2021ReEstablished
//         measles2021NewlyAchieved
//         measles2021Achieved
//         measles2021NotAchieved
//         measles2022NotEndorsed
//         measles2022ReEstablished
//         measles2022NewlyAchieved
//         measles2022Achieved
//         measles2022NotAchieved
//         measles2023NotEndorsed
//         measles2023ReEstablished
//         measles2023NewlyAchieved
//         measles2023Achieved
//         measles2023NotAchieved
//         measles2024NotEndorsed
//         measles2024ReEstablished
//         measles2024NewlyAchieved
//         measles2024Achieved
//         measles2024NotAchieved
//         measles2025NotEndorsed
//         measles2025ReEstablished
//         measles2025NewlyAchieved
//         measles2025Achieved
//         measles2025NotAchieved
//         measles2026NotEndorsed
//         measles2026ReEstablished
//         measles2026NewlyAchieved
//         measles2026Achieved
//         measles2026NotAchieved
//         measles2027NotEndorsed
//         measles2027ReEstablished
//         measles2027NewlyAchieved
//         measles2027Achieved
//         measles2027NotAchieved
//         measles2028NotEndorsed
//         measles2028ReEstablished
//         measles2028NewlyAchieved
//         measles2028Achieved
//         measles2028NotAchieved
//         measles2029NotEndorsed
//         measles2029ReEstablished
//         measles2029NewlyAchieved
//         measles2029Achieved
//         measles2029NotAchieved
//         measles2030NotEndorsed
//         measles2030ReEstablished
//         measles2030NewlyAchieved
//         measles2030Achieved
//         measles2030NotAchieved

//         rubella2020NotEndorsed
//         rubella2020ReEstablished
//         rubella2020NewlyAchieved
//         rubella2020Achieved
//         rubella2020NotAchieved
//         rubella2021NotEndorsed
//         rubella2021ReEstablished
//         rubella2021NewlyAchieved
//         rubella2021Achieved
//         rubella2021NotAchieved
//         rubella2022NotEndorsed
//         rubella2022ReEstablished
//         rubella2022NewlyAchieved
//         rubella2022Achieved
//         rubella2022NotAchieved
//         rubella2023NotEndorsed
//         rubella2023ReEstablished
//         rubella2023NewlyAchieved
//         rubella2023Achieved
//         rubella2023NotAchieved
//         rubella2024NotEndorsed
//         rubella2024ReEstablished
//         rubella2024NewlyAchieved
//         rubella2024Achieved
//         rubella2024NotAchieved
//         rubella2025NotEndorsed
//         rubella2025ReEstablished
//         rubella2025NewlyAchieved
//         rubella2025Achieved
//         rubella2025NotAchieved
//         rubella2026NotEndorsed
//         rubella2026ReEstablished
//         rubella2026NewlyAchieved
//         rubella2026Achieved
//         rubella2026NotAchieved
//         rubella2027NotEndorsed
//         rubella2027ReEstablished
//         rubella2027NewlyAchieved
//         rubella2027Achieved
//         rubella2027NotAchieved
//         rubella2028NotEndorsed
//         rubella2028ReEstablished
//         rubella2028NewlyAchieved
//         rubella2028Achieved
//         rubella2028NotAchieved
//         rubella2029NotEndorsed
//         rubella2029ReEstablished
//         rubella2029NewlyAchieved
//         rubella2029Achieved
//         rubella2029NotAchieved
//         rubella2030NotEndorsed
//         rubella2030ReEstablished
//         rubella2030NewlyAchieved
//         rubella2030Achieved
//         rubella2030NotAchieved

//         wPV2020NotEndorsed
//         wPV2020ReEstablished
//         wPV2020NewlyAchieved
//         wPV2020Achieved
//         wPV2020NotAchieved
//         wPV2021NotEndorsed
//         wPV2021ReEstablished
//         wPV2021NewlyAchieved
//         wPV2021Achieved
//         wPV2021NotAchieved
//         wPV2022NotEndorsed
//         wPV2022ReEstablished
//         wPV2022NewlyAchieved
//         wPV2022Achieved
//         wPV2022NotAchieved
//         wPV2023NotEndorsed
//         wPV2023ReEstablished
//         wPV2023NewlyAchieved
//         wPV2023Achieved
//         wPV2023NotAchieved
//         wPV2024NotEndorsed
//         wPV2024ReEstablished
//         wPV2024NewlyAchieved
//         wPV2024Achieved
//         wPV2024NotAchieved
//         wPV2025NotEndorsed
//         wPV2025ReEstablished
//         wPV2025NewlyAchieved
//         wPV2025Achieved
//         wPV2025NotAchieved
//         wPV2026NotEndorsed
//         wPV2026ReEstablished
//         wPV2026NewlyAchieved
//         wPV2026Achieved
//         wPV2026NotAchieved
//         wPV2027NotEndorsed
//         wPV2027ReEstablished
//         wPV2027NewlyAchieved
//         wPV2027Achieved
//         wPV2027NotAchieved
//         wPV2028NotEndorsed
//         wPV2028ReEstablished
//         wPV2028NewlyAchieved
//         wPV2028Achieved
//         wPV2028NotAchieved
//         wPV2029NotEndorsed
//         wPV2029ReEstablished
//         wPV2029NewlyAchieved
//         wPV2029Achieved
//         wPV2029NotAchieved
//         wPV2030NotEndorsed
//         wPV2030ReEstablished
//         wPV2030NewlyAchieved
//         wPV2030Achieved
//         wPV2030NotAchieved

//         jev2020NotEndorsed
//         jev2020ReEstablished
//         jev2020NewlyAchieved
//         jev2020Achieved
//         jev2020NotAchieved
//         jev2021NotEndorsed
//         jev2021ReEstablished
//         jev2021NewlyAchieved
//         jev2021Achieved
//         jev2021NotAchieved
//         jev2022NotEndorsed
//         jev2022ReEstablished
//         jev2022NewlyAchieved
//         jev2022Achieved
//         jev2022NotAchieved
//         jev2023NotEndorsed
//         jev2023ReEstablished
//         jev2023NewlyAchieved
//         jev2023Achieved
//         jev2023NotAchieved
//         jev2024NotEndorsed
//         jev2024ReEstablished
//         jev2024NewlyAchieved
//         jev2024Achieved
//         jev2024NotAchieved
//         jev2025NotEndorsed
//         jev2025ReEstablished
//         jev2025NewlyAchieved
//         jev2025Achieved
//         jev2025NotAchieved
//         jev2026NotEndorsed
//         jev2026ReEstablished
//         jev2026NewlyAchieved
//         jev2026Achieved
//         jev2026NotAchieved
//         jev2027NotEndorsed
//         jev2027ReEstablished
//         jev2027NewlyAchieved
//         jev2027Achieved
//         jev2027NotAchieved
//         jev2028NotEndorsed
//         jev2028ReEstablished
//         jev2028NewlyAchieved
//         jev2028Achieved
//         jev2028NotAchieved
//         jev2029NotEndorsed
//         jev2029ReEstablished
//         jev2029NewlyAchieved
//         jev2029Achieved
//         jev2029NotAchieved
//         jev2030NotEndorsed
//         jev2030ReEstablished
//         jev2030NewlyAchieved
//         jev2030Achieved
//         jev2030NotAchieved

//         hpv2020NotEndorsed
//         hpv2020ReEstablished
//         hpv2020NewlyAchieved
//         hpv2020Achieved
//         hpv2020NotAchieved
//         hpv2021NotEndorsed
//         hpv2021ReEstablished
//         hpv2021NewlyAchieved
//         hpv2021Achieved
//         hpv2021NotAchieved
//         hpv2022NotEndorsed
//         hpv2022ReEstablished
//         hpv2022NewlyAchieved
//         hpv2022Achieved
//         hpv2022NotAchieved
//         hpv2023NotEndorsed
//         hpv2023ReEstablished
//         hpv2023NewlyAchieved
//         hpv2023Achieved
//         hpv2023NotAchieved
//         hpv2024NotEndorsed
//         hpv2024ReEstablished
//         hpv2024NewlyAchieved
//         hpv2024Achieved
//         hpv2024NotAchieved
//         hpv2025NotEndorsed
//         hpv2025ReEstablished
//         hpv2025NewlyAchieved
//         hpv2025Achieved
//         hpv2025NotAchieved
//         hpv2026NotEndorsed
//         hpv2026ReEstablished
//         hpv2026NewlyAchieved
//         hpv2026Achieved
//         hpv2026NotAchieved
//         hpv2027NotEndorsed
//         hpv2027ReEstablished
//         hpv2027NewlyAchieved
//         hpv2027Achieved
//         hpv2027NotAchieved
//         hpv2028NotEndorsed
//         hpv2028ReEstablished
//         hpv2028NewlyAchieved
//         hpv2028Achieved
//         hpv2028NotAchieved
//         hpv2029NotEndorsed
//         hpv2029ReEstablished
//         hpv2029NewlyAchieved
//         hpv2029Achieved
//         hpv2029NotAchieved
//         hpv2030NotEndorsed
//         hpv2030ReEstablished
//         hpv2030NewlyAchieved
//         hpv2030Achieved
//         hpv2030NotAchieved

//         tetanus2020NotEndorsed
//         tetanus2020ReEstablished
//         tetanus2020NewlyAchieved
//         tetanus2020Achieved
//         tetanus2020NotAchieved
//         tetanus2021NotEndorsed
//         tetanus2021ReEstablished
//         tetanus2021NewlyAchieved
//         tetanus2021Achieved
//         tetanus2021NotAchieved
//         tetanus2022NotEndorsed
//         tetanus2022ReEstablished
//         tetanus2022NewlyAchieved
//         tetanus2022Achieved
//         tetanus2022NotAchieved
//         tetanus2023NotEndorsed
//         tetanus2023ReEstablished
//         tetanus2023NewlyAchieved
//         tetanus2023Achieved
//         tetanus2023NotAchieved
//         tetanus2024NotEndorsed
//         tetanus2024ReEstablished
//         tetanus2024NewlyAchieved
//         tetanus2024Achieved
//         tetanus2024NotAchieved
//         tetanus2025NotEndorsed
//         tetanus2025ReEstablished
//         tetanus2025NewlyAchieved
//         tetanus2025Achieved
//         tetanus2025NotAchieved
//         tetanus2026NotEndorsed
//         tetanus2026ReEstablished
//         tetanus2026NewlyAchieved
//         tetanus2026Achieved
//         tetanus2026NotAchieved
//         tetanus2027NotEndorsed
//         tetanus2027ReEstablished
//         tetanus2027NewlyAchieved
//         tetanus2027Achieved
//         tetanus2027NotAchieved
//         tetanus2028NotEndorsed
//         tetanus2028ReEstablished
//         tetanus2028NewlyAchieved
//         tetanus2028Achieved
//         tetanus2028NotAchieved
//         tetanus2029NotEndorsed
//         tetanus2029ReEstablished
//         tetanus2029NewlyAchieved
//         tetanus2029Achieved
//         tetanus2029NotAchieved
//         tetanus2030NotEndorsed
//         tetanus2030ReEstablished
//         tetanus2030NewlyAchieved
//         tetanus2030Achieved
//         tetanus2030NotAchieved

//         hepb2020NotEndorsed
//         hepb2020ReEstablished
//         hepb2020NewlyAchieved
//         hepb2020Achieved
//         hepb2020NotAchieved
//         hepb2021NotEndorsed
//         hepb2021ReEstablished
//         hepb2021NewlyAchieved
//         hepb2021Achieved
//         hepb2021NotAchieved
//         hepb2022NotEndorsed
//         hepb2022ReEstablished
//         hepb2022NewlyAchieved
//         hepb2022Achieved
//         hepb2022NotAchieved
//         hepb2023NotEndorsed
//         hepb2023ReEstablished
//         hepb2023NewlyAchieved
//         hepb2023Achieved
//         hepb2023NotAchieved
//         hepb2024NotEndorsed
//         hepb2024ReEstablished
//         hepb2024NewlyAchieved
//         hepb2024Achieved
//         hepb2024NotAchieved
//         hepb2025NotEndorsed
//         hepb2025ReEstablished
//         hepb2025NewlyAchieved
//         hepb2025Achieved
//         hepb2025NotAchieved
//         hepb2026NotEndorsed
//         hepb2026ReEstablished
//         hepb2026NewlyAchieved
//         hepb2026Achieved
//         hepb2026NotAchieved
//         hepb2027NotEndorsed
//         hepb2027ReEstablished
//         hepb2027NewlyAchieved
//         hepb2027Achieved
//         hepb2027NotAchieved
//         hepb2028NotEndorsed
//         hepb2028ReEstablished
//         hepb2028NewlyAchieved
//         hepb2028Achieved
//         hepb2028NotAchieved
//         hepb2029NotEndorsed
//         hepb2029ReEstablished
//         hepb2029NewlyAchieved
//         hepb2029Achieved
//         hepb2029NotAchieved
//         hepb2030NotEndorsed
//         hepb2030ReEstablished
//         hepb2030NewlyAchieved
//         hepb2030Achieved
//         hepb2030NotAchieved

//         mNT2020NotEndorsed
//         mNT2020ReEstablished
//         mNT2020NewlyAchieved
//         mNT2020Achieved
//         mNT2020NotAchieved
//         mNT2021NotEndorsed
//         mNT2021ReEstablished
//         mNT2021NewlyAchieved
//         mNT2021Achieved
//         mNT2021NotAchieved
//         mNT2022NotEndorsed
//         mNT2022ReEstablished
//         mNT2022NewlyAchieved
//         mNT2022Achieved
//         mNT2022NotAchieved
//         mNT2023NotEndorsed
//         mNT2023ReEstablished
//         mNT2023NewlyAchieved
//         mNT2023Achieved
//         mNT2023NotAchieved
//         mNT2024NotEndorsed
//         mNT2024ReEstablished
//         mNT2024NewlyAchieved
//         mNT2024Achieved
//         mNT2024NotAchieved
//         mNT2025NotEndorsed
//         mNT2025ReEstablished
//         mNT2025NewlyAchieved
//         mNT2025Achieved
//         mNT2025NotAchieved
//         mNT2026NotEndorsed
//         mNT2026ReEstablished
//         mNT2026NewlyAchieved
//         mNT2026Achieved
//         mNT2026NotAchieved
//         mNT2027NotEndorsed
//         mNT2027ReEstablished
//         mNT2027NewlyAchieved
//         mNT2027Achieved
//         mNT2027NotAchieved
//         mNT2028NotEndorsed
//         mNT2028ReEstablished
//         mNT2028NewlyAchieved
//         mNT2028Achieved
//         mNT2028NotAchieved
//         mNT2029NotEndorsed
//         mNT2029ReEstablished
//         mNT2029NewlyAchieved
//         mNT2029Achieved
//         mNT2029NotAchieved
//         mNT2030NotEndorsed
//         mNT2030ReEstablished
//         mNT2030NewlyAchieved
//         mNT2030Achieved
//         mNT2030NotAchieved
//       }
//     }
//   }
// `
