import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { MediaQueries as Mq } from '../../styles/MediaQueries'

const Container = styled.div`
  background-color: var(--paleBlue);
  width: 350px;
  height: 548px;
  margin: 1rem 3rem 5rem 3rem;
  border-radius: 10px;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  ${Mq.xs} {
    margin: 3rem;
  }
`
const BarCol = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  // align-items: center;
  flex: 1;
`
const Bar = styled.div`
  width: 2.5rem;
  min-width: 2.5rem;
  height: 100%;
  background-color: ${({ color }) => `var(--${color})`};
`
// const Blank = styled.div`
//   width: 2.5rem;
//   min-width: 2.5rem;
//   height: 100%;
//   outline: 1px solid var(--lightGray);
//   outline-offset: -1px;
//   background: ${({ print }) =>
//     print
//       ? 'none'
//       : `linear-gradient(
//   to top right,
//   rgba(0, 0, 0, 0) 0%,
//   rgba(0, 0, 0, 0) calc(50% - 0.8px),
//   rgba(0, 0, 0, 0.25) 50%,
//   rgba(0, 0, 0, 0) calc(50% + 0.8px),
//   rgba(0, 0, 0, 0) 100%
// )`};
//   background-color: ${({ print }) =>
//     print ? 'var(--lightestGray)' : 'var(--white)'};
//   border: 1px solid var(--lightGray);
// `
const ChartTitle = styled.div`
  font-weight: bold;
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
  color: ${({ color }) => `var(--${color})`};
  ${Mq.xs} {
    font-size: 1.3rem;
  }
`

// const Line = styled.div`
//   width: 4rem;
//   border-top: 3px solid var(--darkGray);
//   margin-left: -0.75rem;
//   margin-top: -1px;
// `
const Row = styled.div`
  display: flex;
  align-items: center;
  height: ${(props) => `${props.height}`};
`
const Label = styled.div`
  font-size: 1.15rem;
  margin-left: 1rem;
  line-height: 1.11;
  ${Mq.xs} {
    font-size: 0.85rem;
    line-height: 1.2;
  }
`
const HowToRead12 = ({ print }) => {
  const { t } = useTranslation()
  return (
    <Container>
      <ChartTitle color={'baseBlue'}>{t('how to read this chart')}</ChartTitle>

      <BarCol>
        {/* <Row height={'20%'}>
          <Blank print={print} />
          <Label>
            {t('countries with')}
            <br />
            <strong> {t('no endorsed target')}</strong>
          </Label>
        </Row>
        <Line /> */}
        <Row height={'35%'}>
          <Bar color={'darkOrange'} />
          <Label>
            {t('countries that have')}
            <br />
            <strong> {t('not achieved')} </strong>
            {t('target')}
          </Label>
        </Row>
        <Row height={'30%'}>
          <Bar color={'mediumOrange'} />
          <Label>
            {t('disease is')}
            <strong> {t('re-established')} </strong> <br />
            {t('in this country')}
          </Label>
        </Row>
        {/* <Row height={'17%'}>
          <Bar color={'mediumBlue'} />
          <Label>
            {t('countries that have')}
            <strong> {t('newly achieved')} </strong>
            {t('target in this year')}
          </Label>
        </Row> */}
        <Row height={'35%'}>
          <Bar color={'brightBlue'} />
          <Label>
            {t('countries that have')}
            <br />
            <strong> {t('achieved')} </strong>
            {t('target')}
          </Label>
        </Row>
      </BarCol>
    </Container>
  )
}

export default HowToRead12
