import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import {
  CHART_TITLES_IG12_API
  //NUM_COUNTRIES_IN_REG
  //GBL_NUM_COUNTRIES
} from '../../utils/const'
import { MediaQueries as Mq } from '../../styles/MediaQueries'
import { wrangleIg12Data } from '../../utils/helpers'
import HowToRead12 from './HowToRead12'
import ReactTooltip from 'react-tooltip'

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 4rem -3rem 4rem 0;
  ${Mq.xs} {
    margin: 0;
  }
`

const MiniChartContainer = styled.div`
  margin: 1rem 3rem 5rem 3rem;
  width: ${({ maxWidth }) => maxWidth};
`
const ChartTitle = styled.div`
  font-weight: bold;
  font-size: 1.5rem;
  margin-bottom: ${({ mb }) => mb};

  color: ${({ color }) => `var(--${color})`};
  ${Mq.xs} {
    font-size: 1.35rem;
  }
`
const MiniChart = styled.figure`
  width: 100%;
  height: 400px;
  display: flex;
  position: relative;
  //background-color: pink;
`

const BarCol = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  max-width: 80px;
  height: 100%;
  // background-color: ${({ color }) => color};
  //border: 1px solid var(--lightestGray);
  margin: 0 10px;
  :nth-child(1) {
    margin-left: 0;
  }
  .tooltip {
    font-weight: bold;
    font-size: 1.25rem;
  }
`
const Bar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  //position: absolute;
  //bottom: ${({ bottom }) => bottom};
  height: ${({ height }) => height};
  width: 100%;
  background-color: ${({ color }) => `var(--${color})`};
  opacity: 1;
  color: var(--white);
  font-weight: bold;
`
// const BarLine = styled.div`
//   width: 100%;
//   background-color: var(--darkGray);
//   height: 2px;
//   position: absolute;
//   bottom: ${({ height }) =>
//     height === '0%' ? `${height}` : `calc(${height} - 2px)`};
// `
const BarLabel = styled.div`
  font-size: 1.1rem;
  font-weight: ${({ weight }) => weight};
  color: var(--darkGray);
  //position: absolute;
  //bottom: ${({ position }) => position};
  margin: 0 -0.5rem;
  text-align: center;
  line-height: 1.2;
`
const BarLabelContainer = styled.div`
  position: absolute;
  height: 5rem;
  bottom: -6rem;
`
// const Line = styled.div`
//   width: 100%;
//   height: ${({ height }) => height};
//   border-top: ${({ color }) => `2px solid var(--${color})`};
//   position: absolute;
//   left: 0;
//   bottom: 0;
//   z-index: 5;
// `
// const Label = styled.div`
//   font-weight: bold;
//   font-size: 1.15rem;
//   color: var(--darkGray);
//   width: 100%;
//   text-align: right;
//   position: absolute;
//   top: ${({ position }) => position};
// `
const Blank = styled.div`
  width: 100%;
  height: ${(props) => `${props.height}`};
  outline: 1px solid var(--lightGray);
  outline-offset: -1px;
  background: ${({ print }) =>
    print
      ? 'none'
      : `linear-gradient(
    to top right,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) calc(50% - 0.8px),
    rgba(0, 0, 0, 0.25) 50%,
    rgba(0, 0, 0, 0) calc(50% + 0.8px),
    rgba(0, 0, 0, 0) 100%
  )`};
  background-color: ${({ print }) =>
    print ? 'var(--lightestGray)' : 'var(--white)'};
  border: 1px solid var(--lightGray);
`
// const ChartNotes = styled.div`
//   opacity: 0.9;
//   font-size: 1.15rem;
//   margin-bottom: 5rem;
// `
const IG12Chart = ({ data, region, title, print }) => {
  const { t } = useTranslation()
  //const formattedData = wrangle12Data(data)

  const formattedData = wrangleIg12Data(data)

  //find highest number of years of data in any chart
  const findLongestArray = (data) => {
    let max = 0

    data.forEach((value) => {
      if (value.length > max) max = value.length
    })
    return max
  }
  const longestArray = findLongestArray(formattedData)

  const calcMaxWidth = () => {
    return longestArray <= 2 ? '300px' : '400px'
  }
  const scale = formattedData[0][0].denominator //NUM_COUNTRIES_IN_REG[region] //number of countries

  //const footnotes = { wPV: <sup>1</sup>, mNT: <sup>1</sup> }
  const order = ['MEASLES', 'RUBELLA', 'POLIO', 'NTETANUS']

  const ordered = order.map((ord, i) => {
    return formattedData.find((fd) => fd[0].pathogen === ord)
  })
  const BASELINE_YEAR = 2020
  return (
    <Container>
      <HowToRead12 print={print} />
      {ordered.map((value, i) => {
        return (
          <MiniChartContainer maxWidth={calcMaxWidth()} key={value[0].pathogen}>
            <ChartTitle
              color={'brightBlue'}
              mb={value[0].pathogen === 'NTETANUS' ? '1rem' : '3rem'}
            >
              {t(CHART_TITLES_IG12_API[value[0].pathogen])}
              {/* {footnotes[key]} */}
            </ChartTitle>

            <MiniChart
              role="img"
              alt={`${t(
                'Stacked bar chart showing number countries who have achieved their target out of those who have endorsed a target for'
              )} ${value[0].pathogen}.`}
            >
              {value.map((d, i) => (
                <BarCol key={value[0].pathogen + i}>
                  {/* Countries that have not endorsed */}
                  <ReactTooltip
                    place={'right'}
                    type={'dark'}
                    effect={'solid'}
                    className="tooltip"
                  />
                  {/* handles if d does not have data, i.e. just year and pathogen properties */}
                  {Object.keys(d).length <= 2 ? null : (
                    <Blank
                      height={`${(d.NotEndorsed / scale) * 100}%`}
                      print={print}
                    ></Blank>
                  )}
                  {/* <BarLine
                      height={`${
                        ((scale - value[0].NotEndorsed) / scale) * 100
                      }%`}
                    /> */}
                  {/* <p data-tip="Hello tooltip">Tooltip</p> */}

                  {/* Endemic (Not Achieved) */}
                  <Bar
                    height={`${(d.NotAchieved / scale) * 100}%`}
                    color={'darkOrange'}
                    data-tip={
                      (d.NotAchieved / scale) * 100 < 4 ? d.NotAchieved : null
                    }
                  >
                    {(d.NotAchieved / scale) * 100 > 4 ? d.NotAchieved : null}
                  </Bar>

                  {/* Re-Established */}
                  <Bar
                    height={`${(d.ReEstablished / scale) * 100}%`}
                    color={'mediumOrange'}
                    data-tip={
                      (d.ReEstablished / scale) * 100 < 4
                        ? d.ReEstablished
                        : null
                    }
                  >
                    {(d.ReEstablished / scale) * 100 > 4
                      ? d.ReEstablished
                      : null}
                  </Bar>
                  {/* Newly Achieved
                  <Bar
                    height={`${(d.NewlyAchieved / scale) * 100}%`}
                    color={'paleBlue'}
                    data-tip={
                      (d.NewlyAchieved / scale) * 100 < 4
                        ? d.NewlyAchieved
                        : null
                    }
                  >
                    {(d.NewlyAchieved / scale) * 100 > 4
                      ? d.NewlyAchieved
                      : null}
                  </Bar> */}
                  {/* Achieved */}
                  <Bar
                    height={`${(d.Achieved / scale) * 100}%`}
                    color={'brightBlue'}
                    data-tip={
                      (d.Achieved / scale) * 100 < 4 ? d.Achieved : null
                    }
                  >
                    {(d.Achieved / scale) * 100 > 4 ? d.Achieved : null}
                    <BarLabelContainer>
                      <BarLabel
                        position={'-40px'}
                        weight={d.year === BASELINE_YEAR ? 'bold' : 'normal'}
                      >
                        {d.year === BASELINE_YEAR ? t('Baseline') : d.year}
                      </BarLabel>
                    </BarLabelContainer>
                  </Bar>
                </BarCol>
              ))}
              {/* <Line
                height={`${
                  100
                  // ((scale - value[key === 'mNT' ? 1 : 0].NotEndorsed) / scale) *
                  // 100
                }%`}
                color={'darkGray'}
              >
                <Label position={'-50px'}>{t('endorsing')}</Label>
                <Label position={'-30px'}>{t('Target')}</Label>
                <Label position={'0px'}>{scale}</Label>
                <Label position={'20px'}> &nbsp;{t('countries')}</Label>
              </Line> */}
            </MiniChart>
          </MiniChartContainer>
        )
      })}
    </Container>
  )
}

export default IG12Chart
